















































import { ItemDaVendaComReferencias } from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindMotivoDeCancelamentoFiscalUseCase } from '@/usecases'
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import SeletorDeMotivosDeCancelamentoFiscal from './SeletorDeMotivosDeCancelamentoFiscal.vue'

@Component({
	components: {
		SeletorDeMotivosDeCancelamentoFiscal,
	},
})
export default class ConfirmacaoComMotivoFiscal extends Vue {
	@Prop({ default: 'Confirma?' }) titulo!: string
	@Prop() subtitulo?: string
	@Prop() opcaoPadrao?: 'Não' | 'Sim'
	@Prop({ default: '50' }) width!: string
	@Prop() loja?: string
	@Ref() botaoNao!: { $el: HTMLButtonElement }
	@Ref() botaoSim!: { $el: HTMLButtonElement }
	resolveDialogo: ((value: boolean) => void) | null = null
	obrigatorio = obrigatorio
	motivo: string | null = null
	errors: string[] = []
	item: ItemDaVendaComReferencias | null = null
	mostra: boolean | null = null
	findMotivoDoCancelamentoFiscal = new FindMotivoDeCancelamentoFiscalUseCase

	nega() {
		this.resolveDialogo && this.resolveDialogo(false)
		this.$emit('negar')
		this.mostra = false
	}
 
	async  confirma() {

		if (!this.motivo || this.motivo === undefined) {
			AlertModule.setError('Necessário um motivo para cancelamento fiscal')
			return
		}

		const motivoDeCancelamento = (await (this.findMotivoDoCancelamentoFiscal
		).findAll(this.motivo)).content.filter(motivo => motivo.id === this.motivo)[0].motivo

		if(motivoDeCancelamento.length < 15){
			AlertModule.setError("Necessario que o motivo tenha mais de 15 caracteres, edite ou selecione outro!");
			return
		}

		this.resolveDialogo && this.resolveDialogo(true)
		this.$emit('confirmar', this.motivo, this.item)
		this.mostra = false
	} 

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) {
			this.resolveDialogo && this.resolveDialogo(false)
			return
		}
		setTimeout(() => {
			switch (this.opcaoPadrao) {
				case 'Não':
					return this.botaoNao.$el.focus()
				case 'Sim':
					return this.botaoSim.$el.focus()
			}
		}, 125)
	}

}
